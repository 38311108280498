import NextHead from 'next/head';

export type Props = {
	title: string;
	robots?: string;
};

export const Head = ({ title, robots = 'none' }: Props): JSX.Element => {
	return (
		<NextHead>
			<title>{title} | マネーフォワード Pay for Business</title>
			<meta name="robots" content={robots} />
		</NextHead>
	);
};
