import type { ParsedUrlQuery } from 'node:querystring';

export const isString = (query: ParsedUrlQuery[string]): query is string =>
	typeof query === 'string';

export const getString = (query: ParsedUrlQuery[string]): string | undefined =>
	isString(query) ? query : undefined;

export const getSerialNumber = (
	query: ParsedUrlQuery[string],
): string | undefined => (isString(query) ? query.toUpperCase() : undefined);
type OptionType = {
	treatEmptyAsUndefined?: boolean;
};
export const getNumber = (
	query: ParsedUrlQuery[string],
	{ treatEmptyAsUndefined = false }: OptionType = {},
): number | undefined => {
	const isEmpty = query === '';
	if (
		!(treatEmptyAsUndefined && isEmpty) &&
		isString(query) &&
		// biome-ignore lint/suspicious/noGlobalIsNan: This is a valid case
		!isNaN(+query)
	) {
		return +query;
	}
	return undefined;
};

/**
 * Returns the value of a query parameter with a value of type Boolean.
 *
 * @param query
 * @returns boolean or undefind, if the string is "true" or "false", otherwise returns undefined.
 */
export const getBoolean = (
	query: ParsedUrlQuery[string],
): boolean | undefined => {
	if (!isString(query)) return undefined;

	const lowercasedValue = query.toLowerCase();

	switch (lowercasedValue) {
		case 'true':
			return true;
		case 'false':
			return false;
		default:
			break;
	}
};

/**
 *
 * @param query
 * @returns true if string matches yyyy-mm-dd and is valid date
 */
export const isDate = (query: ParsedUrlQuery[string]): query is string => {
	if (!isString(query)) return false;

	// reject overflow 2021-02-29 -> 2021-03-01
	const date = new Date(query);
	const day = date.getUTCDate().toString().padStart(2, '0');
	const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
	const year = date.getUTCFullYear().toString();
	const parsedDate = `${year}-${month}-${day}`;

	return query === parsedDate;
};

export const getDate = (query: ParsedUrlQuery[string]): string | undefined =>
	isDate(query) ? query : undefined;

export const getIso8601DateTime = (
	query: ParsedUrlQuery[string],
	limit: 'lte' | 'gte',
): string | undefined => {
	const dateString = getDate(query);
	if (!dateString) return undefined;

	const actual = new Date(
		limit === 'gte'
			? new Date(dateString).setHours(0, 0, 0, 0)
			: new Date(dateString).setHours(23, 59, 59, 999),
	);

	return actual.toISOString();
};

export const getIso8601StartOfMonthDateTimeJst = (
	query: ParsedUrlQuery[string],
): string | undefined => {
	const dateString = getDate(query);
	if (!dateString) return undefined;

	const date = new Date(dateString);
	date.setUTCFullYear(date.getUTCFullYear(), date.getUTCMonth(), 1);
	date.setUTCHours(-9, 0, 0, 0);

	return date.toISOString();
};

export const getIso8601EndOfMonthDateTimeJst = (
	query: ParsedUrlQuery[string],
): string | undefined => {
	const dateString = getDate(query);
	if (!dateString) return undefined;

	const date = new Date(dateString);
	date.setUTCFullYear(date.getUTCFullYear(), date.getUTCMonth() + 1, 0);
	date.setUTCHours(14, 59, 59, 999);

	return date.toISOString();
};
