import { CircularProgress } from '@/components/new/CircularProgress';
import { Table } from '@/components/new/Table';
import { Image } from '@/components/ui/Image';
import type { ApolloError } from '@apollo/client';
import noSearchResults from '@images/noSearchResults.svg';
import queryError from '@images/queryError.svg';
import React from 'react';
import styles from './styles.module.css';

export interface DataTableItem {
	id: string;
}
export type DataTableProps<T extends DataTableItem> = {
	loading: boolean;
	error: ApolloError | undefined;
	items: T[];
	queryErrorIllustration?: React.ReactNode;
	queryErrorMessage?: React.ReactNode;
	noItemsIllustration?: React.ReactNode;
	noItemsMessage: React.ReactNode;
	thead: JSX.Element;
	renderItem: (item: T) => JSX.Element;
};
export const DataTable = <T extends DataTableItem>({
	loading,
	error,
	items,
	queryErrorIllustration = (
		<Image src={queryError} alt="query error" width={250} height={200} />
	),
	queryErrorMessage = <span className={styles.message}>読み込みエラー</span>,
	noItemsIllustration = (
		<Image src={noSearchResults} alt="no results" width={250} height={200} />
	),
	noItemsMessage,
	thead,
	renderItem,
}: DataTableProps<T>): JSX.Element => {
	if (loading)
		return (
			<div className={styles.noItemWrapper}>
				<CircularProgress size="xlarge" />
			</div>
		);

	if (error)
		return (
			<div className={styles.noItemWrapper}>
				{queryErrorIllustration}
				{queryErrorMessage}
			</div>
		);

	if (items.length === 0)
		return (
			<div className={styles.noItemWrapper}>
				{noItemsIllustration}
				{noItemsMessage}
			</div>
		);

	return (
		<Table.Root>
			<Table.Head>{thead}</Table.Head>
			<Table.Body>
				{items.map((item) => (
					<React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
				))}
			</Table.Body>
		</Table.Root>
	);
};
