import { forwardRef } from 'react';
import styles from './styles.module.css';

const Icon = () => (
	// biome-ignore lint/a11y/noSvgWithoutTitle: TODO
	<svg
		viewBox="0 0 14 11"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={styles.icon}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5 10.42L0 5.42L1.41 4.01L5 7.59L12.59 0L14 1.42L5 10.42Z"
		/>
	</svg>
);

type Props = {
	name?: string;
	checked?: boolean;
	defaultChecked?: boolean;
	disabled?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
	children?: React.ReactNode;
} & Pick<React.InputHTMLAttributes<HTMLInputElement>, 'value'>;

export const Checkbox = forwardRef<HTMLInputElement, Props>(
	({ children, ...rest }, ref) => (
		<label className={styles.label}>
			<div className={styles.checkboxWrapper}>
				<input className={styles.input} type="checkbox" ref={ref} {...rest} />
				<span className={styles.box} />
				<span className={styles.iconWrapper}>
					<Icon />
				</span>
			</div>
			<span className={styles.labelText}>{children}</span>
		</label>
	),
);
Checkbox.displayName = 'Checkbox';
