export const LeftArrowIcon = () => (
	// biome-ignore lint/a11y/noSvgWithoutTitle: TODO
	<svg
		width="6"
		height="12"
		viewBox="0 0 6 12"
		fill="transparent"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4.9214 1.75732L1.15016 5.99997L4.9214 10.2426"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
