import { getNumber, isString } from '@/lib/query';
import { useRouter } from 'next/router';

export const RESULTS_PER_PAGE = 50;

type PaginateForwardVariables = {
	after?: string;
	first: number;
	before?: never;
	last?: never;
};
type PaginateBackVariables = {
	before: string;
	last: number;
	after?: never;
	first?: never;
};
type PaginationVariables = PaginateBackVariables | PaginateForwardVariables;
export const usePaginationVariables = (): PaginationVariables => {
	const router = useRouter();
	const resultsPerPage =
		getNumber(router.query.resultsPerPage) ?? RESULTS_PER_PAGE;

	if (isString(router.query.before))
		return {
			before: router.query.before,
			last: resultsPerPage,
		};

	return {
		...(isString(router.query.after) && { after: router.query.after }),
		first: resultsPerPage,
	};
};
