import type { PresignedUrlDetailsFragment } from '@/graphql';
import { useLogger } from '@/hooks/useLogger';
import { useGlobalNotification } from './useGlobalNotification';

type DownloadCSVFunction = (
	presignedUrlDetails: PresignedUrlDetailsFragment,
	filename?: string,
	trimTimestamp?: boolean,
) => Promise<void>;
export const useDownloadCSV = (): DownloadCSVFunction => {
	const { logError } = useLogger();
	const { showErrorNotification } = useGlobalNotification();

	const downloadCSV: DownloadCSVFunction = async (
		{ url, headers },
		filename = 'export',
		trimTimestamp = false,
	): Promise<void> => {
		try {
			const res = await fetch(url, {
				headers: {
					'x-amz-server-side-encryption-customer-algorithm':
						headers.xAmzServerSideEncryptionCustomerAlgorithm,
					'x-amz-server-side-encryption-customer-key':
						headers.xAmzServerSideEncryptionCustomerKey,
					'x-amz-server-side-encryption-customer-key-MD5':
						headers.xAmzServerSideEncryptionCustomerKeyMd5,
					Accept: 'text/csv',
				},
			});

			if (!res.ok) {
				const errorText = await res.text();

				const error = new Error(errorText);
				error.name = 'FetchUnsuccessfulError';

				throw error;
			}

			const blob = await res.blob();
			const blobUrl = URL.createObjectURL(blob);

			const a = document.createElement('a');
			a.href = blobUrl;
			a.download = trimTimestamp
				? `${filename}.csv`
				: `${filename}_${getTimestamp()}.csv`;
			a.click();
			URL.revokeObjectURL(blobUrl);
		} catch (error) {
			if (error instanceof Error) {
				logError(error.name, error.message, error.stack);
			} else {
				logError('UnknownError', error);
			}

			showErrorNotification(
				'予期せぬエラーが発生しました。繰り返し同様の操作をしても改善されない場合はお問い合わせください。',
			);
		}
	};

	return downloadCSV;
};

export const getTimestamp = (current: Date = new Date()): string => {
	const year = current.getFullYear().toString();
	const month = (current.getMonth() + 1).toString().padStart(2, '0');
	const date = current.getDate().toString().padStart(2, '0');
	const hours = current.getHours().toString().padStart(2, '0');
	const minutes = current.getMinutes().toString().padStart(2, '0');
	const seconds = current.getSeconds().toString().padStart(2, '0');

	return year + month + date + hours + minutes + seconds;
};
