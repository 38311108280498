import type { PresignedUrlDetailsFragment } from '@/graphql';

export type UploadFileFunction = (
	presignedUrlDetails: PresignedUrlDetailsFragment,
	file: File,
) => Promise<void>;

export const useUploadFile = (): UploadFileFunction => {
	const uploadFile: UploadFileFunction = async ({ url, headers }, file) => {
		const res = await fetch(url, {
			method: 'PUT',
			headers: {
				'x-amz-server-side-encryption-customer-algorithm':
					headers.xAmzServerSideEncryptionCustomerAlgorithm,
				'x-amz-server-side-encryption-customer-key':
					headers.xAmzServerSideEncryptionCustomerKey,
				'x-amz-server-side-encryption-customer-key-MD5':
					headers.xAmzServerSideEncryptionCustomerKeyMd5,
			},
			body: file,
		});

		if (!res.ok) {
			const errorText = await res.text();

			throw new Error(errorText);
		}
	};

	return uploadFile;
};
