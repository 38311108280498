import { TextField } from '@/components/new/TextField';
import type { ChangeEvent, ComponentPropsWithRef } from 'react';
import {
	type FieldValues,
	type UseControllerProps,
	useController,
} from 'react-hook-form';

export type AmountInputProps<T extends FieldValues> = UseControllerProps<T> &
	ComponentPropsWithRef<typeof TextField>;
export const AmountInput = <T extends FieldValues>({
	control,
	name,
	...textFieldProps
}: AmountInputProps<T>): JSX.Element => {
	const { field } = useController<T>({
		control,
		name,
	});

	return (
		<TextField
			{...field}
			{...textFieldProps}
			value={formatValue(field.value)}
			onChange={(e) => field.onChange(handleChange(e))}
		/>
	);
};

const formatValue = (value: string): string => {
	switch (value) {
		case undefined:
			return '';
		case '-':
		case '':
			return value;
		default:
			return (+value).toLocaleString();
	}
};

const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
	const isNegative = /^-/.test(value);
	const stripped = value.replace(/\D/g, '');

	return isNegative ? `-${stripped}` : stripped;
};
