export const RightArrowIcon = () => (
	// biome-ignore lint/a11y/noSvgWithoutTitle: TODO
	<svg
		width="7"
		height="12"
		viewBox="0 0 7 12"
		fill="transparent"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.9214 10.2427L5.69263 6.00004L1.9214 1.7574"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
