import { TextField } from '@/components/new/TextField';
import { type ComponentPropsWithRef, forwardRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ hasValue: boolean }>(({ hasValue }) =>
	hasValue
		? undefined
		: {
				'& input:not(:focus)::-webkit-datetime-edit': {
					color: 'transparent',
				},
				'& input::-webkit-calendar-picker-indicator': {
					opacity: '0.6',
				},
			},
);

export const Datepicker = forwardRef<
	HTMLInputElement,
	ComponentPropsWithRef<typeof TextField> & { hasValue: boolean }
>(({ hasValue, ...props }, ref) => {
	return (
		<Wrapper hasValue={hasValue}>
			<TextField {...props} ref={ref} type="date" />
		</Wrapper>
	);
});

Datepicker.displayName = 'Datepicker';
